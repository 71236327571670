import React from 'react';
import Request from '../../../request';
import { AppContext } from "../../../context";
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Datatable from '../../Datatable';
import { Col, Row } from 'react-bootstrap';

class ResponderDenunciasList extends React.Component {

    static contextType = AppContext;

    updateDatatable = () => {
        this.refs.datatable.updateDatatable();
    }

    onAction(action) {
        switch (action.name) {
            case "class":
                if (action.status === "Recebida" || action.status === "Em Diligencia") {
                    Request('GET', this.context.config.BACKEND_URL + '/denuncia/responder/' + action.id, this.context.token)
                        .then(res => {
                            const denuncia = res.body;
                            this.context.setContent("ResponderDenunciasForm", { responder: denuncia });
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na recuperação do registro: " + err.toString() });
                        });
                } else if (window.confirm("Confirma o recebimento da denúncia?")) {
                    Request('PUT', this.context.config.BACKEND_URL + '/denuncia/responder/status/' + action.id, this.context.token)
                        .then(res => {
                            this.context.setContent("ResponderDenunciasForm", { responder: res.body });
                        })
                        .catch(err => {
                            this.context.addToast({ titulo: "Erro", conteudo: "Houve uma falha na atualização do status da denúncia: " + err.toString() });
                        });
                }
                break;
            case "download":
                const downloadUrl = `${this.context.config.BACKEND_URL}/denuncia/responder/${action.id}/pdf`;
                fetch(downloadUrl, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${this.context.token}`,  // Inclui o token no cabeçalho
                    },
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Falha ao gerar o PDF');
                        }
                        return response.blob();  // Recebe o PDF como um blob
                    })
                    .then((blob) => {
                        // Cria uma URL temporária para o blob e inicia o download
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = `denuncia_${action.id}.pdf`;
                        document.body.appendChild(a);  // Anexa o link ao DOM temporariamente
                        a.click();  // Dispara o clique para baixar
                        document.body.removeChild(a);  // Remove o link após o download
                        window.URL.revokeObjectURL(url);  // Libera o objeto URL
                    })
                    .catch((err) => {
                        this.context.addToast({
                            titulo: 'Erro',
                            conteudo: `Houve uma falha no download do PDF: ${err.toString()}`,
                        });
                    });
                break;
            default:
        }
    }

    render() {
        return (
            <>
                <h3><FontAwesomeIcon icon={faFolderOpen} /> Responder Denúncias Encaminhadas</h3>
                <h6>
                    <strong> Prazo de resposta das Denuncias
                        <a
                            className="btn btn-link-dark"
                            target="_blank"
                            rel="noreferrer"
                            href={`${this.context.config.FRONTEND_URL}/doc/DECRETO_N1.253_REGIMENTO_INTERNO_DISQUE_DENUNCIA.pdf`}
                            download="DECRETO_N1.253_REGIMENTO_INTERNO_DISQUE_DENUNCIA.pdf"
                        >
                            (Art 6º do Decreto Nº 1.253/2020)
                        </a>
                    </strong>
                </h6>
                <Row>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom: "5px", marginRight: "5px", backgroundColor: '#0ee824', padding: '5px 5px' }}>No Prazo</span>
                        Do despacho até 15º dia
                    </Col>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom: "5px", marginRight: "5px", backgroundColor: '#ffff00', padding: '5px 5px' }}>Prorrogada</span>
                        Do 16º dia ao 25º dia
                    </Col>
                    <Col>
                        <span style={{ display: 'inline-block', width: '85px', height: '35px', marginBottom: "5px", marginRight: "5px", backgroundColor: '#d61515', color: "white", padding: '5px 5px' }}>Atrasada</span>
                        26º dia em diante
                    </Col>


                </Row>


                <Datatable
                    ref="datatable"
                    url={this.context.config.BACKEND_URL + '/denuncia/responder'}
                    autoSeach={true}
                    onError={(err) => this.context.addToast({ titulo: "Erro", conteudo: err.toString() })}
                    onAction={(action, update) => this.onAction(action, update)}
                    onClickAdd={() => this.context.setContent('DenunciasEnviadasForm', { responder: {} })}
                    updateDatatable={this.updateDatatable}
                />

            </>
        );
    }
}

export default ResponderDenunciasList;
