import {
  faClone,
  faDownload,
  faExclamationCircle,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Form as BSForm,
  FormGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Map from "../../Map";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AddAnexos from "../../AddAnexos";
import Diacritics from "diacritic";
import { AppContext } from "../../../context";

class AvaliarDenunciaForm extends React.Component {
  static contextType = AppContext;

  state = {
    cidades: [],
    bairros: [],
    canais: [],
    denuncias: [],
    envolvidos: [],
    atendentes: [],
  };

  anexoRef = React.createRef();
  timeout = null;

  clone(values, update) {
    const url = this.context.config.BACKEND_URL + "/denuncia/clonar";
    Request("POST", url, this.context.token)
      .send(values)
      .then((res) => {
        const { DenunciaId } = res.body; // Assume que o ID da denúncia clonada está disponível na resposta
        if (window.confirm(`Denúncia Clonada com o id ${DenunciaId}`)) {
          this.context.addToast({
            titulo: "Successo",
            conteudo: "Denúncia clonada com sucesso.",
          });
          this.context.setContent("DenunciaRegistradaList");
        }
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha ao clonar a denúncia.",
          err,
        });
      });
  }
  

  save(values, update) {
    const url =
      this.context.config.BACKEND_URL +
      "/denuncia/avaliar/" +
      this.props.avaliar.id;
    Request("PUT", url, this.context.token)
      .send(values)
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        this.context.setContent("AvaliarDenunciaList");
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
          err,
        });
      });
  }

  setFilter(filter, latitude, longitude) {
    this.setState(
      () => ({ filter }),
      () => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(
          () => this.searchDenuncias(filter, latitude, longitude),
          400
        );
      }
    );
  }

  searchDenuncias(filter, latitude, longitude) {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/denuncia/relacionamentos",
      this.context.token
    )
      .send({ filter, latitude, longitude })
      .then((response) => {
        this.setState(() => ({ denuncias: response.body }));
      })
      .catch(errorHandler);
  }

  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa: " + err.toString(),
      });

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/usuario/disquedenuncia/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ atendentes: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/canal/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ canais: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/natureza/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ naturezas: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);
  }

  render() {

    
    const center = this.props.avaliar.latitude && this.props.avaliar.longitude 
    ? [this.props.avaliar.latitude, this.props.avaliar.longitude] 
    :[-1.4631897998991463, -48.4955653127071868]; // ou um valor padrão apropriado
      const options = [
      { value: "Em Registro", label: "Em Registro" },
      { value: "Registrada", label: "Registrada" },
      { value: "Devolvida", label: "Devolvida" },
      { value: "Avaliada", label: "Avaliada" },
      { value: "Arquivada", label: "Arquivada" },
    ];

    const motivo_arquivamento = [
      {
        value: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
        label: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
      },
      {
        value: "Denuncia direcionada a outro orgao",
        label: "Denuncia direcionada a outro orgao",
      },
      { value: "Denuncia duplicada", label: "Denuncia duplicada" },
      {
        value: "Denuncia com dados incompreensiveis/confusos",
        label: "Denuncia com dados incompreensiveis/confusos",
      },
    ];

    const motivo_devolucao = [
      { value: "Gramatica", label: "Gramatica" },
      { value: "Estrutura", label: "Estrutura" },
      { value: "Natureza", label: "Natureza" },
      { value: "Outro", label: "Outro" },
    ];
    const status_apensos = [
      { value: "Avaliado", label: "Avaliado" },
      { value: "Validado", label: "Validado" },
      { value: "Recusado", label: "Recusado" },
    ];


    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...this.props.avaliar,
          Anexos: this.props.avaliar?.Anexos ?? [],
          Apensos: this.props.avaliar?.Apensos ?? [],
          envolvidos: this.props.avaliar?.envolvidos ?? [],
          CidadeId: this.props.avaliar?.CidadeId,
          BairroId: this.props.avaliar?.BairroId,
          NaturezaId: this.props.avaliar?.NaturezaId,
          relacionamentos: this.props.avaliar?.relacionamentos ?? [],
          UsuarioId: this.props.avaliar?.UsuarioId ?? this.context.usuario.id,
          urgente: this.props.avaliar?.urgente ?? false,
        }}
        validate={(values) => {
          const errors = {};

          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          if (!values.CanalId) {
            errors.CanalId = "Campo obrigatório";
          }
          if (errors.length > 0) {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Verifique os campos obrigatórios e tente novamente.",
            });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setValues, setFieldValue }) => {
          return (
            <>
              <Form>
                <Tabs defaultActiveKey="dados-basicos" className="mb-3">
                  <Tab eventKey="dados-basicos" title={<span style={{fontWeight:"bold"}}>DENUNCIA: {this.props.avaliar.id}</span>}>
                    <FormGroup>
                      <Row>
                        <Col md={6} className="d-flex flex-column">
                          <Row>
                            <Col md={12}>
                              <small>
                                Arraste o mapa ou clique no botão de pesquisa
                                para encontrar um endereço.
                              </small>
                            </Col>
                          </Row>
                          <Row className="flex-grow-1">
                            <Col md="12">
                              <Map
                                onChange={(data) => {
                                  const CidadeId = (
                                    this.state.cidades.find(
                                      (cidade) =>
                                        Diacritics.clean(
                                          cidade.label
                                        ).toUpperCase() ===
                                        Diacritics.clean(
                                          data.cidade
                                        ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;
                                  const BairroId = (
                                    this.state.bairros.find(
                                      (bairro) =>
                                        bairro.CidadeId === CidadeId &&
                                        Diacritics.clean(
                                          bairro.label
                                        ).toUpperCase() ===
                                          Diacritics.clean(
                                            data.bairro
                                          ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;

                                  setValues({
                                    ...values,
                                    ...data,
                                    CidadeId,
                                    BairroId,
                                  });
                                }}
                                center={center??[values.latitude, values.longitude]}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <BSForm.Label as="b">Latitude</BSForm.Label>

                              <Field
                                type="text"
                                name="latitude"
                                className="form-control"
                                value={values.latitude}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <BSForm.Label as="b">Longitude</BSForm.Label>

                              <Field
                                type="text"
                                name="longitude"
                                className="form-control"
                                value={values.longitude}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Row>
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  Canal<span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="CanalId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CanalId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.canais}
                                  value={
                                    this.state.canais
                                      ? this.state.canais.find(
                                          (option) =>
                                            option.value === values.CanalId
                                        )
                                      : ""
                                  }
                                  onChange={(option) =>
                                    setFieldValue(
                                      "CanalId",
                                      option ? option.value : null
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={8}>
                                <BSForm.Label as="b">Natureza</BSForm.Label>

                                <Select
                                  name="NaturezaId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.naturezas}
                                  value={
                                    this.state.naturezas
                                      ? this.state.naturezas.find(
                                          (option) =>
                                            option.value === values.NaturezaId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "NaturezaId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {values.descricao_original != null ? (
                            <FormGroup>
                              <BSForm.Label as="b">
                                Descrição Original
                              </BSForm.Label>
                              <Field
                                readOnly
                                as="textarea"
                                name="descricao_original"
                                className="form-control"
                                value={values.descricao_original}
                                style={{ height: '200px' }}
                              />
                            </FormGroup>
                          ) : null}
                          <FormGroup>
                            <BSForm.Label as="b">
                              Descrição<span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="descricao"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              as="textarea"
                              name="descricao"
                              className="form-control"
                              value={values.descricao}
                              style={{ height: '200px' }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="9">
                                <BSForm.Label as="b">Logradouro</BSForm.Label>

                                <Field
                                  type="text"
                                  name="logradouro"
                                  className="form-control"
                                  value={values.logradouro}
                                />
                              </Col>
                              <Col md="3">
                                <BSForm.Label as="b">Número</BSForm.Label>
                                <Field
                                  type="text"
                                  name="numero"
                                  className="form-control"
                                  value={values.numero}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="6">
                                <BSForm.Label as="b">Cidade</BSForm.Label>

                                <Select
                                  name="CidadeId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.cidades}
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                          (option) =>
                                            option.value === values.CidadeId
                                        )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "CidadeId",
                                      option ? option.value : null
                                    );
                                    setFieldValue("BairroId", null);
                                  }}
                                />
                              </Col>
                              <Col md="6">
                                <BSForm.Label as="b">Bairro</BSForm.Label>

                                <CreatableSelect
                                  name="BairroId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  onCreateOption={(label) => {
                                    this.setState(
                                      (state) => ({
                                        bairros: [
                                          {
                                            value: "new",
                                            label: label,
                                            CidadeId: values.CidadeId,
                                          },
                                          ...state.bairros,
                                        ],
                                      }),
                                      () => {
                                        setFieldValue("BairroId", "new");
                                        setFieldValue("bairro", label);
                                      }
                                    );
                                  }}
                                  placeholder="Pesquisar..."
                                  formatCreateLabel={(label) =>
                                    "Adicionar " + label
                                  }
                                  options={this.state.bairros.filter(
                                    (bairro) =>
                                      bairro.CidadeId === values.CidadeId
                                  )}
                                  value={this.state.bairros.find(
                                    (bairro) => bairro.value === values.BairroId
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("BairroId", option.value);
                                    setFieldValue("bairro", option.label);
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <BSForm.Label as="b">Estabelecimento</BSForm.Label>
                            <Field
                              type="text"
                              name="estabelecimento"
                              className="form-control"
                              value={values.estabelecimento}
                            />
                          </FormGroup>
                          <Row>
                            <Col lg={6}>
                              <BSForm.Label as="b">Referência</BSForm.Label>
                              <Field
                                type="text"
                                name="referencia"
                                className="form-control"
                                value={values.referencia}
                              />
                            </Col>
                            <Col lg={6}>
                              <BSForm.Label as="b">Status</BSForm.Label>
                              <Select
                                name="status"
                                options={options}
                                value={options.find(
                                  (option) => option.value === values.status
                                )}
                                onChange={(selectedOption) => {
                                  const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                  setFieldValue("status", selectedValue);
                                }}
                              />
                            </Col>
                          </Row>
                          {values.status === "Arquivada" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <Select
                                  name="motivo"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={motivo_arquivamento}
                                  value={
                                    values.motivo
                                      ? motivo_arquivamento.find(
                                          (motivo) =>
                                            motivo.value === values.motivo
                                        )
                                      : ""
                                  }
                                  onChange={(selectedMotivo) => {
                                    const motivoValue = selectedMotivo
                                      ? selectedMotivo.value
                                      : null;
                                    setFieldValue("motivo", motivoValue);
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : null}

                          {values.status === "Devolvida" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <Select
                                  name="motivo"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={motivo_devolucao}
                                  value={
                                    values.motivo
                                      ? motivo_devolucao.find(
                                          (motivo) =>
                                            motivo.value === values.motivo
                                        )
                                      : ""
                                  }
                                  onChange={(selectedMotivo) => {
                                    const motivoValue = selectedMotivo
                                      ? selectedMotivo.value
                                      : null;
                                    setFieldValue("motivo", motivoValue);
                                  }}
                                />
                              </Col>
                              <Col>
                                <BSForm.Label as="b">Atendente </BSForm.Label>
                                <Select
                                  name="UsuarioID"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.atendentes}
                                  value={
                                    values.UsuarioId
                                      ? this.state.atendentes.find(
                                          (atendente) =>
                                            atendente.value === values.UsuarioId
                                        )
                                      : ""
                                  }
                                  onChange={(selectedAtendente) => {
                                    setFieldValue(
                                      "UsuarioId",
                                      selectedAtendente
                                        ? selectedAtendente.value
                                        : null
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          ) : null}
                          <br></br>
                          <Row>
                            <Col lg={3}>
                              <div
                                style={{
                                  backgroundColor: "#ff6947",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <Field
                                  type="checkbox"
                                  name="urgente"
                                  className="text-right"
                                />
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faExclamationCircle} />
                                <BSForm.Label
                                  as="b"
                                  style={{ marginLeft: "10px" }}
                                >
                                  Urgente
                                </BSForm.Label>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Tab>
                  <Tab eventKey="anexos" title="Anexos">
                    <input
                      type="file"
                      multiple
                      ref={this.anexoRef}
                      className="d-none"
                      onChange={(event) => {
                        setFieldValue("Anexos", [
                          ...values.Anexos,
                          ...event.currentTarget.files,
                        ]);
                      }}
                    />
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Nome</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos.map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.nome ?? ""}</td>
                            <td className="text-center">
                              {anexo.id ? (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`}
                                  target="_blank"
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              ) : null}
                              <Button
                                size="sm"
                                type="button"
                                variant="danger"
                                title="Excluir"
                                onClick={() =>
                                  window.confirm(
                                    "Deseja realmente excluir este arquivo?"
                                  ) &&
                                  setFieldValue(
                                    "Anexos",
                                    values.Anexos.filter((a) => a !== anexo)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4">
                            <AddAnexos
                              multiple={true}
                              onError={(file) =>
                                this.context.addToast({
                                  titulo: "Erro",
                                  conteudo:
                                    "Falha ao carregar o arquivo " + file.name,
                                })
                              }
                              onLoad={(anexos) =>
                                setFieldValue("Anexos", [
                                  ...values.Anexos,
                                  ...anexos,
                                ])
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Tab>
                  {this.props.avaliar?.Apensos?.length > 0 ? (
                    <Tab eventKey="apensos" title="Apenso">
                      {values.Apensos &&
                        values.Apensos.map((apenso, index) => (
                          <Row key={index} className="mb-2">
                            <Col>
                              <BSForm.Label as="b">Conteúdo</BSForm.Label>
                              <Field
                                as="textarea"
                                type="text"
                                name={`Apensos[${index}].conteudo`}
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue(
                                    `Apensos[${index}].conteudo`,
                                    e.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col>
                              <BSForm.Label as="b">Status</BSForm.Label>
                              <div>
                                {status_apensos.map((status, statusIndex) => (
                                  <div
                                    key={statusIndex}
                                    className="form-check form-check-inline"
                                  >
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`Apensos[${index}].status`}
                                      id={`status_${index}_${statusIndex}`}
                                      value={status.value}
                                      checked={
                                        values.Apensos[index].status ===
                                        status.value
                                      }
                                      onChange={() => {
                                        const updatedApensos =
                                          values.Apensos.map(
                                            (apenso, apensoIndex) => {
                                              if (apensoIndex === index) {
                                                return {
                                                  ...apenso,
                                                  status: status.value,
                                                };
                                              } else {
                                                return apenso;
                                              }
                                            }
                                          );
                                        setFieldValue(
                                          "Apensos",
                                          updatedApensos
                                        );
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`status_${index}_${statusIndex}`}
                                    >
                                      {status.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Col>
                          </Row>
                        ))}
                    </Tab>
                  ) : null}
                </Tabs>
                <FormGroup className="text-right">
                  <Button
                    type="button"
                    onClick={() => this.clone(values)}
                    disabled={isSubmitting}
                    variant="warning"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faClone} />
                    &nbsp; Clonar Denuncia
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Gravar Alterações
                  </Button>
                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default AvaliarDenunciaForm;
