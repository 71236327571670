import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Form as BSForm, FormGroup } from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AppContext } from "../../../context";

class UsuarioSetorForm extends React.Component {
  static contextType = AppContext;

  save(values, callback) {
    const method = "POST";
    const url = this.context.config.BACKEND_URL + "/usuario/setor/observacao";

    Request(method, url, this.context.token)
      .send({ ...values })
      .then((res) => {
        this.context.addToast({
          titulo: "Successo",
          conteudo: "Registro atualizado com sucesso.",
        });
        callback();
        if (this.props.onSave) this.props.onSave();
        this.context.closeModal();
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }

  render() {
    return (
      <Formik
        initialValues={{
          ...this.props.usuario,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.conteudo) {
            errors.conteudo = "Campo obrigatório";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <FormGroup>
                <BSForm.Label>Conteúdo</BSForm.Label>
                <ErrorMessage
                  name="conteudo"
                  component="span"
                  className="text-danger small ml-2"
                />
                <Field
                  as="textarea"
                  name="conteudo"
                  className="form-control"
                  rows="6" 
                  value={values.conteudo}
                  placeholder={`Comentário:\nExiste um funcionário que irá substituir?\nSe sim, preencha os seguintes campos:\nCPF:\nEmail:\nNome:\n`}
                  style={{ height: '200px' }}
                />
              </FormGroup>
              <FormGroup className="text-right">
                <Button
                  type="button"
                  variant="secondary"
                  onClick={() => this.context.closeModal()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                  &nbsp; Fechar
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="primary"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faSave} />
                  &nbsp; Gravar Alterações
                </Button>
              </FormGroup>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default UsuarioSetorForm;
