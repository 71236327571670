import { faClone, faDownload, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Col,
  Form as BSForm,
  FormGroup,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import Request from "../../../request";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Map from "../../Map";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AddAnexos from "../../AddAnexos";
import Diacritics from "diacritic";
import { AppContext } from "../../../context";

class RegistrarDenunciaForm extends React.Component {
  static contextType = AppContext;
  state = {
    cidades: [],
    naturezas: [],
    bairros: [],
    canais: [],
  };
  componentDidMount() {
    const errorHandler = (err) =>
      this.context.addToast({
        titulo: "Erro",
        conteudo: "Houve uma falha na pesquisa.",
      });
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/canal/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ canais: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/natureza/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ naturezas: options.body }));
      })
      .catch(errorHandler);
    Request(
      "GET",
      this.context.config.BACKEND_URL + "/cidade/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ cidades: options.body }));
      })
      .catch(errorHandler);

    Request(
      "GET",
      this.context.config.BACKEND_URL + "/bairro/options",
      this.context.token
    )
      .send()
      .then((options) => {
        this.setState(() => ({ bairros: options.body }));
      })
      .catch(errorHandler);
  }


  clone(values, update) {
    const url = this.context.config.BACKEND_URL + "/denuncia/clonar";
    Request("POST", url, this.context.token)
      .send(values)
      .then((res) => {
        const { DenunciaId } = res.body;
        if (window.confirm(`Denúncia clonada com o id ${DenunciaId}`)) {
          this.context.addToast({
            titulo: "Successo",
            conteudo: "Denúncia clonada com sucesso.",
          });
          this.context.setContent("DenunciaRegistradaList");
        }
      })
      .catch((err) => {
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha ao clonar a denúncia.",
          err,
        });
      });
  }


  save(values, callback) {
    const method = this.props.denuncia?.id ? "PUT" : "POST";
    const url =
      this.context.config.BACKEND_URL +
      "/denuncia/emRegistro" +
      (this.props.denuncia?.id ? "/" + this.props.denuncia?.id : "");

    Request(method, url, this.context.token)
      .send(values)
      .then((res) => {

        const { DenunciaId } = res.body || {};

        if (DenunciaId) {
          if (window.confirm(`Denúncia ${DenunciaId} atualizada com sucesso`)) {
            this.context.addToast({
              titulo: "Sucesso",
              conteudo: "Registro atualizado com sucesso.",
            });
            callback();
            this.context.setContent("DenunciaRegistradaList");
          }
        } else {
          this.context.addToast({
            titulo: "Erro",
            conteudo: "Houve um problema ao atualizar a denúncia.",
          });
          callback();
        }
      })
      .catch((err) => {
        console.log(err);
        this.context.addToast({
          titulo: "Erro",
          conteudo: "Houve uma falha na gravação do registro.",
        });
        callback();
      });
  }



  render() {
    const options = [
      { value: "Em Registro", label: "Em Registro" },
      { value: "Registrada", label: "Registrada" },
      { value: "Arquivada", label: "Arquivada" },
      { value: "Finalizada", label: "Finalizada" },
    ];
    if (this.props.denuncia?.status === "Devolvida")
      options.push({ value: "Devolvida", label: "Devolvida" });
    if (this.props.denuncia?.status === "Desarquivada")
      options.push({ value: "Desarquivada", label: "Desarquivada" });

    const motivo_arquivamento = [
      {
        value: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
        label: "Denuncia com dados incompletos (Denunciado/Endereço/Relato",
      },
      {
        value: "Denuncia direcionada a outro orgao",
        label: "Denuncia direcionada a outro orgao",
      },
      { value: "Denuncia duplicada", label: "Denuncia duplicada" },
      {
        value: "Denuncia com dados incompreensiveis/confusos",
        label: "Denuncia com dados incompreensiveis/confusos",
      },
    ];

    const center = [this.props.denuncia.latitude??-1.4631897998991463, this.props.denuncia.longitude??-48.4955653127071868];
    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          ...this.props.denuncia,
          CanalId: this.props.denuncia?.CanalId ?? 1,
          CidadeId: this.props.denuncia?.CidadeId,
          BairroId: this.props.denuncia?.BairroId,
          NaturezaId: this.props.denuncia?.NaturezaId,
          Apensos: this.props.denuncia?.Apensos ?? [],
          Anexos: this.props.denuncia?.Anexos ?? [],
          UsuarioId: this.props.denuncia?.UsuarioId ?? this.context.usuario.id,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.descricao) {
            errors.descricao = "Campo obrigatório";
          }
          if (!values.CanalId) {
            errors.CanalId = "Campo obrigatório";
          }
          if (errors.length > 0) {
            this.context.addToast({
              titulo: "Erro",
              conteudo: "Verifique os campos obrigatórios e tente novamente.",
            });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
       
          this.save(values, () => setSubmitting(false));
        }}
      >
        {({ isSubmitting, values, setValues, setFieldValue }) => {
          return (
            <>
   
              <Form>
                <Tabs defaultActiveKey="dados-basicos" className="mb-3">
                  <Tab eventKey="dados-basicos" title={<span style={{fontWeight:"bold"}}>DENUNCIA: {this.props.denuncia?.id}</span>}>
                    <FormGroup>
                      <Row>
                        <Col md={6} className="d-flex flex-column">
                          <Row>
                            <Col md={12}>
                              <small>
                                Arraste o mapa ou clique no botão de pesquisa
                                para encontrar um endereço.
                              </small>
                            </Col>
                          </Row>
                          <Row className="flex-grow-1">
                            <Col md="12">
                              <Map
                                onChange={(data) => {
                                  const CidadeId = (
                                    this.state.cidades.find(
                                      (cidade) =>
                                        Diacritics.clean(
                                          cidade.label
                                        ).toUpperCase() ===
                                        Diacritics.clean(
                                          data.cidade
                                        ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;
                                  const BairroId = (
                                    this.state.bairros.find(
                                      (bairro) =>
                                        bairro.CidadeId === CidadeId &&
                                        Diacritics.clean(
                                          bairro.label
                                        ).toUpperCase() ===
                                        Diacritics.clean(
                                          data.bairro
                                        ).toUpperCase()
                                    ) ?? { value: null }
                                  ).value;
                                  setValues({
                                    ...values,
                                    ...data,
                                    CidadeId,
                                    BairroId,
                                  });
                                }}
                                center={center??[values.latitude, values.longitude]}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <BSForm.Label as="b">Latitude</BSForm.Label>
                              <Field
                                type="text"
                                name="latitude"
                                className="form-control"
                                value={values.latitude}
                                readOnly
                              />
                            </Col>
                            <Col md="6">
                              <BSForm.Label as="b">Longitude</BSForm.Label>
                              <Field
                                type="text"
                                name="longitude"
                                className="form-control"
                                value={values.longitude}
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                           

                            <Row>
                              <Col lg={4}>
                                <BSForm.Label as="b">
                                  Canal<span className="text-danger">*</span>
                                </BSForm.Label>
                                <ErrorMessage
                                  name="CanalId"
                                  component="span"
                                  className="text-danger small ml-2"
                                />
                                <Select
                                  name="CanalId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.canais}
                                  value={
                                    this.state.canais
                                      ? this.state.canais.find(
                                        (option) =>
                                          option.value === values.CanalId
                                      )
                                      : ""
                                  }
                                  onChange={(option) =>
                                    setFieldValue(
                                      "CanalId",
                                      option ? option.value : null
                                    )
                                  }
                                />
                              </Col>
                              <Col lg={8}>
                                <BSForm.Label as="b">Natureza</BSForm.Label>
                                <Select
                                  name="NaturezaId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.naturezas}
                                  value={
                                    this.state.naturezas
                                      ? this.state.naturezas.find(
                                        (option) =>
                                          option.value === values.NaturezaId
                                      )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "NaturezaId",
                                      option ? option.value : null
                                    );
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          {values.descricao_original != null ? (
                            <FormGroup>
                              <BSForm.Label as="b">
                                Descrição Original
                              </BSForm.Label>
                              <Field
                                disabled
                                as="textarea"
                                name="descricao_original"
                                className="form-control"
                                value={values.descricao_original}
                                style={{ height: '200px' }}
                              />
                            </FormGroup>
                          ) : null}
                          <FormGroup>
                            <BSForm.Label as="b">
                              Descrição<span className="text-danger">*</span>
                            </BSForm.Label>
                            <ErrorMessage
                              name="descricao"
                              component="span"
                              className="text-danger small ml-2"
                            />
                            <Field
                              as="textarea"
                              name="descricao"
                              className="form-control"
                              value={values.descricao}
                              style={{ height: '200px' }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="9">
                                <BSForm.Label as="b">Logradouro</BSForm.Label>
                                <Field
                                  type="text"
                                  name="logradouro"
                                  className="form-control"
                                  value={values.logradouro}
                                />
                              </Col>
                              <Col md="3">
                                <BSForm.Label as="b">Número</BSForm.Label>
                                <Field
                                  type="text"
                                  name="numero"
                                  className="form-control"
                                  value={values.numero}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col md="6">
                                <BSForm.Label as="b">Cidade</BSForm.Label>
                                <Select
                                  name="CidadeId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={this.state.cidades}
                                  value={
                                    this.state.cidades
                                      ? this.state.cidades.find(
                                        (option) =>
                                          option.value === values.CidadeId
                                      )
                                      : ""
                                  }
                                  onChange={(option) => {
                                    setFieldValue(
                                      "CidadeId",
                                      option ? option.value : null
                                    );
                                    setFieldValue("BairroId", null);
                                  }}
                                />
                              </Col>
                              <Col md="6">
                                <BSForm.Label as="b">Bairro</BSForm.Label>
                                <CreatableSelect
                                  name="BairroId"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  onCreateOption={(label) => {
                                    this.setState(
                                      (state) => ({
                                        bairros: [
                                          {
                                            value: "new",
                                            label: label,
                                            CidadeId: values.CidadeId,
                                          },
                                          ...state.bairros,
                                        ],
                                      }),
                                      () => {
                                        setFieldValue("BairroId", "new");
                                        setFieldValue("bairro", label);
                                      }
                                    );
                                  }}
                                  placeholder="Pesquisar..."
                                  formatCreateLabel={(label) =>
                                    "Adicionar " + label
                                  }
                                  options={this.state.bairros.filter(
                                    (bairro) =>
                                      bairro.CidadeId === values.CidadeId
                                  )}
                                  value={this.state.bairros.find(
                                    (bairro) => bairro.value === values.BairroId
                                  )}
                                  onChange={(option) => {
                                    setFieldValue("BairroId", option.value);
                                    setFieldValue("bairro", option.label);
                                  }}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col>
                                <BSForm.Label as="b">
                                  Estabelecimento
                                </BSForm.Label>
                                <Field
                                  type="text"
                                  name="estabelecimento"
                                  className="form-control"
                                  value={values.estabelecimento}
                                />
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row>
                            <Col lg="6">
                              <BSForm.Label as="b">Referência</BSForm.Label>
                              <Field
                                type="text"
                                name="referencia"
                                className="form-control"
                                value={values.referencia}
                              />
                            </Col>
                            <Col>
                              {/* {this.props.denuncia && this.props.denuncia.id ? <> */}
                              <BSForm.Label as="b">Status</BSForm.Label>
                              <Select
                                name="status"
                                options={options}
                                value={
                                  values.status
                                    ? options.find(
                                      (option) =>
                                        option.value === values.status
                                    )
                                    : options.find(
                                      (option) =>
                                        option.value === "Em Registro"
                                    )
                                }
                                //value={options.find(option => option.value === values.status)}
                                onChange={(selectedOption) => {
                                  const selectedValue = selectedOption
                                    ? selectedOption.value
                                    : null;
                                  setFieldValue("status", selectedValue);
                                }}
                              />

                              {/* </> : null} */}
                            </Col>
                          </Row>
                          {values.status === "Arquivada" ? (
                            <Row>
                              <Col lg={12}>
                                <BSForm.Label as="b">Motivo</BSForm.Label>
                                <Select
                                  name="motivo"
                                  noOptionsMessage={() => "Nada encontrado."}
                                  placeholder="Pesquisar..."
                                  options={motivo_arquivamento}
                                  value={
                                    values.motivo
                                      ? motivo_arquivamento.find(
                                        (motivo) =>
                                          motivo.value === values.motivo
                                      )
                                      : ""
                                  }
                                  onChange={(selectedMotivo) => {
                                    const motivoValue = selectedMotivo
                                      ? selectedMotivo.value
                                      : null;
                                    setFieldValue("motivo", motivoValue);
                                  }}
                                />
                              </Col>
                              {/* <Col>
                                                        <BSForm.Label as="b">Descrição do Arquivamento<span className="text-danger">*</span></BSForm.Label><ErrorMessage name="descricaoarquivamento" component="span" className="text-danger small ml-2" />
                                                        <Field as="textarea" name="descricaoarquivamento" className="form-control" value={values.descricaoarquivamento} />
                                                    </Col> */}
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Tab>
                  <Tab eventKey="anexos" title="Anexos">
                    <Table striped size="sm" responsive className="mt-2">
                      <thead className="bg-light">
                        <tr>
                          <th>Nome do Anexo</th>
                          <th style={{ width: 120, textAlign: "center" }}>
                            Ações
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.Anexos.map((anexo, key) => (
                          <tr key={key}>
                            <td>{anexo.nome ?? ""}</td>
                            <td className="text-center">
                              {anexo.id && (
                                <Button
                                  as="a"
                                  href={`${this.context.config.BACKEND_URL}/anexo/disquedenuncia/${anexo.id}/${anexo.uuid}`}
                                  download={anexo.nome}
                                  size="sm"
                                  variant="info"
                                  title="Download"
                                  target="_blank"
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                  ></FontAwesomeIcon>
                                </Button>
                              )}
                              <Button
                                size="sm"
                                type="button"
                                variant="danger"
                                title="Excluir"
                                onClick={() =>
                                  window.confirm(
                                    "Deseja realmente excluir este arquivo?"
                                  ) &&
                                  setFieldValue(
                                    "Anexos",
                                    values.Anexos.filter((a) => a !== anexo)
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                ></FontAwesomeIcon>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4">
                            <AddAnexos
                              multiple={true}
                              onError={(file) =>
                                this.context.addToast({
                                  titulo: "Erro",
                                  conteudo:
                                    "Falha ao carregar o arquivo " + file.name,
                                })
                              }
                              onLoad={(anexos) =>
                                setFieldValue("Anexos", [
                                  ...values.Anexos,
                                  ...anexos,
                                ])
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <ErrorMessage
                              name="Anexos"
                              component="span"
                              className="text-danger small ml-2"
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </Tab>
                  {this.props.denuncia?.Apensos?.length > 0 ? (
                    <Tab eventKey="apensos" title="Apenso">
                      {values.Apensos &&
                        values.Apensos.map((apenso, index) => (
                          <Row key={index}>
                            <Col>
                              <BSForm.Label as="b">Conteúdo</BSForm.Label>
                              <Field
                                as="textarea"
                                type="text"
                                name={`Apensos[${index}].conteudo`}
                                className="form-control"
                                onChange={(e) =>
                                  setFieldValue(
                                    `Apensos[${index}].conteudo`,
                                    e.target.value
                                  )
                                }
                              />{" "}
                            </Col>
                          </Row>
                        ))}
                    </Tab>
                  ) : null}
                </Tabs>

                <FormGroup className="text-right">
                  <Button
                    type="button"
                    onClick={() => this.clone(values)}
                    disabled={isSubmitting}
                    variant="warning"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faClone} />
                    &nbsp; Clonar Denuncia
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="primary"
                    className="ml-2"
                  >
                    <FontAwesomeIcon icon={faSave} />
                    &nbsp; Salvar
                  </Button>

                </FormGroup>
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

export default RegistrarDenunciaForm;
